// functional imports
import { useState } from "react";
import { Link } from "react-router-dom";

// visual imports
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'

// import stylesheet
import './css/styles.css';

export function Navbar() 
{
    const [navBar, setNavBar] =  useState(false)

    const handleNavBar = () =>
    {
        setNavBar(!navBar)
    }

    return (
        <div className='flex justify-between items-center h-24 max-w-screen mx-auto 4-px bg-white border-b border-gray-200'>
            
            {/* store logo and name */}
            <div className="flex items-center">
                
                {/* logo */}
                <Link className="text-3xl font-bold pl-4" to="/">
                    <img className="h-24 p-4" src={require("./images/smaeck.jpg")} alt=""/>
                </Link>

                {/* logo name */}
                <h1>
                    <Link className="text-4xl font-bold pl-4 font-['Veteran_Typewriter']" to="/">
                        Smaeck.
                    </Link>
                </h1>
            </div>
            
            {/* navbar tabs top, hidden if screen is too narrow */}
            <ul className="hidden md:flex">

                {/* home */}
                <li className="p-4">
                    <Link to="/">
                        Home
                    </Link>
                </li>

                {/* order */}
                {/* <li className="p-4">
                    <Link to="/order">
                        Bestellen
                    </Link>
                </li> */}

                {/* high tea */}
                <li className="p-4">
                    <Link to="/highteas">
                        High Tea
                    </Link>
                </li>

                {/* contact */}
                <li className="p-4">
                    <Link to="/contact">
                        Contact
                    </Link>
                </li>
            </ul>

            {/* menu icon, appears or disappears based on screen width */}
            <div onClick={handleNavBar} className="block md:hidden pr-4">

                {/* changes the menu icon to a cross when its opened and vice versa */}
                {navBar ? <AiOutlineClose size={25} /> : <AiOutlineMenu size={25} />}
            </div>

            {/* nav bar menu, hidden when screen is wide enough for the tab menu on line 24 */}
            <div className={navBar ? "fixed left-0 top-0 w-[40%] h-full border-r border-r-gray-200 bg-white ease-in-out duration-300" : "fixed left-[-100%]"}>
                
                {/* store name */}
                <h1 className="text-4xl font-bold font-['Veteran_Typewriter'] text-black m-4">Smaeck.</h1>

                {/* nav bar menu links */}
                <ul className="p-2">

                    {/* home */}
                    <li className="p-4 border-b border-gray-100">
                        <Link to="/">
                            Home
                        </Link>
                    </li>

                    {/* order */}
                    {/* <li className="p-4 border-b border-gray-100">
                        <Link to="/order">
                            Bestellen
                        </Link>
                    </li> */}

                    {/* high tea */}
                    <li className="p-4 border-b border-gray-100">
                        <Link to="/highteas">
                            High Tea
                        </Link>
                    </li>

                    {/* contact */}
                    <li className="p-4">
                        <Link to="/contact">
                            Contact
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Navbar;