// imports the router system for the paths
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
// imports for the pages from view
import Homescreen from './Views/Homescreen_View';
import Order from './Views/Orderscreen_View';
import ConfirmedOrder from './Views/Order_Confirmed_View';
import HighTea from './Views/High_Teas_View';
import Contact from './Views/Contact_View';
import LoginScreen from './Views/Login';
import AdminHomeScreen from './Views/Admin_Homescreen';

function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Homescreen />} />
          <Route path="/order" element={<Order />} />
          <Route path="/order-confirmation" element={<ConfirmedOrder />} />
          <Route path="/highteas" element={<HighTea />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<LoginScreen />} />
          {/* TODO: Give name and ID prob after login -> make usestate query on each page to see if sure is logged in ( cookies/session in php api?) */}
          <Route path="/dashboard" element={<AdminHomeScreen />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;