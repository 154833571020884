// navbar and footer imports
import Navbar from "./Navbar_View";
import Footer from "./Contact_Footer_View";

export function Homescreen() 
{
    return (
        <>
            <Navbar />

            <div className="grid md:grid-cols-2 gap-5 p-10">
                
                {/* banner for pc web */}
                <div className="hidden md:grid grid-cols-2 gap-5">
                    <div>
                        <img className="h-auto max-w-full" src={require("./images/IMG-20240220-WA0009.jpg")} alt=""/>
                    </div>
                    <div className="flex flex-col h-auto max-w-full bg-[#668F8F] justify-center items-center">
                        <h1 className="text-3xl font-bold text-white">HIGH TEAS</h1>
                        <p className="text-white">Op locatie of To Go</p>
                    </div>
                    <div className="flex flex-col h-auto max-w-full bg-[#668F8F] justify-center items-center"></div>
                    <div>
                        <img className="h-auto max-w-full" src={require("./images/IMG-20240220-WA0003.jpg")} alt=""/>
                    </div>
                </div>

                {/* banner for mobile web */}
                <div className="md:hidden grid grid-rows-2 gap-5">
                    <div>
                        <img className="h-auto max-w-full" src={require("./images/IMG-20240220-WA0009.jpg")} alt=""/>
                    </div>
                    <div className="flex flex-col h-auto max-w-full bg-[#668F8F] justify-center items-center">
                        <h1 className="text-3xl font-bold text-white">HIGH TEAS</h1>
                        <p className="text-white">Op locatie of To Go</p>
                    </div>
                    <div>
                        <img className="h-auto max-w-full" src={require("./images/IMG-20240220-WA0003.jpg")} alt=""/>
                    </div>
                </div>

                {/* high tea description */}
                <div className="bg-white border border-gray-200 p-[10%]">
                    <h1 className="text-3xl font-bold ">HIGH TEAS</h1>
                    <p className=""><br></br>Bij Smaeck kunt u genieten van een heerlijke High Tea, in de winkel of op uw eigen locatie. 
                                    De High Tea bevat zowel zoete als hartige gerechtjes en alles is zelf gemaakt.
                                    Daarbij kunt u onder andere denken aan een macaron, petit four, mini quiche of mini pizza.
                                    De gerechtjes kunnen variëren, dat hangt ook af van wat er in de winkel gebakken is.
                                    Een High Tea is inclusief onbeperkt thee.<br></br><br></br>
                                    Bij de High Tea to go zit er ook thee bij. Zelf moet u zorgen voor wat schalen of etagères om te presenteren.
                                    Wel is het mogelijk om een etagère met borg te lenen.<br></br><br></br>
                                    Aangezien alles zelf wordt gemaakt dient u ongeveer een week van tevoren te reserveren.</p>
                </div>
            </div>
        
            <Footer />
        </>
    );
}

export default Homescreen;