// navbar and footer imports
import Navbar from "./Navbar_View";
import Footer from "./Contact_Footer_View";

export function Homescreen() 
{
    return (
        <>
            <Navbar />

            {/* banner for order confirmation */}
            <div className="hidden md:flex justify-center h-96 my-20 max-w-screen">
                <div className="flex flex-col justify-center items-center h-96 bg-[#668F8F] px-20">
                    <h1 className="text-3xl font-bold text-white">GELUKT!</h1>
                    <p className="text-white">Uw bestelling is verstuurd!</p>
                </div>

                <img className="" src={require("./images/IMG-20240229-WA0003-lower.jpg")} alt=""/>

            </div>

            <Footer />
        </>
    );
}

export default Homescreen;