import { redirect } from "react-router-dom";

class BlogController {
    static async CreateBlogPost (formData: FormData, functionName: string) {
        try {
            formData.append('function', functionName);

            const response = await fetch('https://smaeckridderkerk.nl/api/api.php', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (data.success) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    static async GetAllBlogPosts (functionName: string) {
        try {
            const response = await fetch('https://smaeckridderkerk.nl/api/api.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    'function': functionName,
                }),
            });

            const data = await response.json();
            if (data.success) {
                return data;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }   
}
export default BlogController;