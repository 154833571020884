import React, { useState } from "react";
import { useEffect } from "react";
import LoginController from "../Controllers/Login_Controller";
import { useNavigate } from "react-router-dom";



export function LoginScreen() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
    };

    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const data = await LoginController.loginCheck(email, password);

            if (data.success) {
                console.log('Login successful');
                // Redirect to the dashboard and pass the user data
                navigate('/dashboard', { state: { name: data.name, id: data.id } });
            } else {
                console.log('Login failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // useEffect(() => {
    //     // Make the API call when the component mounts
    //     fetch('https://smaeckridderkerk.nl/api/api.php', {
    //       method: 'POST', // Specify the method to use
    //       headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded', // Specify the content type
    //       },
    //       body: new URLSearchParams({
    //         'function': 'fetchData' // Send the function parameter in the body
    //       })
    //     })
    //     .then(response => response.text())
    //     .then(data => console.log(data))
    //     .catch(error => console.error('Error:', error));
    //   }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

    return (
        <>
            <div className="container mx-auto">
                <div className=" grid grid-cols-2 gap-2 items-center justify-center h-screen">
                    <div className="flex">
                        <img className="h-auto max-w-full p-4" src={require("./images/smaeck.jpg")} alt=""/>
                        </div>
                    <div className="bg-[#668F8F] p-5">
                        <h1>Login</h1>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                            <input type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" value={email} onChange={handleEmailChange}></input>
                        <label className="block mb-2 text-sm font-medium text-gray-900">Wachtwoord</label>
                        <div className="flex">
                            <input type="password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" value={password} onChange={handlePasswordChange}></input>
                        </div>
                        <button type="button" onClick={handleLogin} className="text-white bg-blue-700 hover:bg-blue-800  font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2">
                            Login
                        </button>
                    </div>

                </div>
            </div>
        </>
    );
}

export default LoginScreen;
