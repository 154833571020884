import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";

import Navbar from "./Navbar_View";
import Footer from "./Contact_Footer_View";

import OrderController from "../Controllers/Order_Controller";

const Order: React.FC = () => {

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [deliverydate, setDeliverydate] = useState("");
    const [description, setDescription] = useState("");

    const handleFirstnameChange = (event: any) => {
        setFirstname(event.target.value);
    };
    const handleLastnameChange = (event: any) => {
        setLastname(event.target.value);
    };
    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };
    const handlePhoneChange = (event: any) => {
        setPhone(event.target.value);
    };
    const handleDeliverydateChange = (event: any) => {
        setDeliverydate(event.target.value);
    };
    const handleDescriptionChange = (event: any) => {
        setDescription(event.target.value);
    };


    const handleOrder = async () => {
        // Call the LoginCheck method from the LoginController class
        await OrderController.PlaceOrder(firstname, lastname, email, phone, deliverydate, description);
    };

  return (
    <>
        <Navbar />

        {/* banner for pc web */}
        <div className="hidden md:flex justify-center h-96 my-10 max-w-screen">
            <div className="flex flex-col justify-center items-center h-96 bg-[#668F8F] px-20">
                <h1 className="text-3xl font-bold text-white">BESTELLEN</h1>
                <p className="text-white">Vers Gebakken Macarons Brownies Cookies Taart</p>
            </div>

            <img className="" src={require("./images/IMG-20240220-WA0047-lower.jpg")} alt=""/>
        </div>

        {/* banner for mobile web */}
        <div className="md:hidden flex justify-center my-10 max-w-screen">
            <div className="flex flex-col justify-center bg-[#668F8F] px-6 max-w-[50%]">
                <h1 className="text-3xl font-bold text-white">BESTELLEN</h1>
                <p className="text-white">Vers Gebakken Macarons Brownies Cookies Taart</p>
            </div>

            <img className="max-w-[50%]" src={require("./images/IMG-20240220-WA0047.jpg")} alt=""/>
        </div>

        {/* order description */}
        <div className="bg-white border border-gray-200 mx-[5%] md:p-[5%] p-[10%] mb-10">
                    <h1 className="text-3xl font-bold ">BESTELLEN</h1>
                    <p className=""><br></br>U heeft de mogelijkheid om online te bestellen en af te halen. Alles is zelfgebakken.
                                            Koeken en brownies kunnen evt per post worden verstuurd.<br></br><br></br>
                                            Tijgersoezen € 2,75<br></br>
                                            Brownies €2,50<br></br>
                                            Bokkepoten € 2,75<br></br>
                                            Hazelnootschuimtaartjes € 2,75<br></br>
                                            Macaron € 2,00<br></br>
                                            Amandel madeleines € 2,00<br></br>
                                            Cookie € 1,75<br></br>
                                            Muffin € 2,00<br></br>
                                            Brookie € 2,75<br></br>
                                            Slofje € 2,75<br></br><br></br>
                                            Taartpunt<br></br>
                                            Carrotcake<br></br>
                                            Red velvet<br></br>
                                            New York cheesecake<br></br>
                                            Chocolade taart<br></br>
                                            Appelroom kruimeltaart<br></br>
                                            Vanille roomvlaai<br></br><br></br>
                                            Maar heeft u zelf ideeën of wensen bel gerust om te overleggen.<br></br><br></br>
                                            Graag ongeveer een week van tevoren bestellen.</p>
        </div>

        {/* order form */}
        <div className="container mx-auto p-4">
            <form>
                <div className="grid gap-6 mb-6 md:grid-cols-2">

                    {/* first name */}
                    <div>
                        <label className="block mb-2 text-sm">Voornaam</label>
                        <input type="text" id="first_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-[#8D5B4C] focus:border-[#8D5B4C] block w-full p-2.5" placeholder="Vooraam" value={firstname} onChange={handleFirstnameChange} required />
                    </div>

                    {/* last name */}
                    <div>
                        <label className="block mb-2 text-sm">Achternaam</label>
                        <input type="text" id="last_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-[#8D5B4C] focus:border-[#8D5B4C] block w-full p-2.5" placeholder="Achternaam" value={lastname} onChange={handleLastnameChange} required />
                    </div>

                    {/* phonenumber */}
                    <div>
                        <label className="block mb-2 text-sm">Telefoonnummer</label>
                        <input type="tel" id="phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-[#8D5B4C] focus:border-[#8D5B4C] block w-full p-2.5" placeholder="06 12345678" value={phone} onChange={handlePhoneChange} required />
                    </div>

                    {/* email */}
                    <div>
                        <label className="block mb-2 text-sm">Email</label>
                        <input type="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-[#8D5B4C] focus:border-[#8D5B4C] block w-full p-2.5" placeholder="example@example.com" value={email} onChange={handleEmailChange} required />
                    </div> 

                    {/* date */}
                    <div className="mb-6">
                        <label className="block mb-2 text-sm">Leverdatum</label>
                        <input type="date" id="password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-[#8D5B4C] focus:border-[#8D5B4C] block w-full p-2.5" placeholder="31-12-2000" value={deliverydate} onChange={handleDeliverydateChange} required />
                    </div> 
                </div>

                {/* description */}
                <div className="mb-6">
                    <label className="block mb-2 text-sm">Omschrijving</label>
                    <input type="text" id="confirm_password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-[#8D5B4C] focus:border-[#8D5B4C] block w-full p-2.5" placeholder="•••••••••" value={description} onChange={handleDescriptionChange} required />
                </div>

                {/* should send data to database and route user to confirmation page */}
                <button onClick={handleOrder} className="mb-6 px-3 py-2 border-2 bg-transparent border-gray-300 hover:bg-white hover:border-[#8D5B4C]">
                    {/* <Link to="/order-confirmation"> */}
                        Verzenden
                    {/* </Link> */}
                </button>        
            </form>
        </div>

        <Footer />
    </>
  );
};

export default Order;