import Navbar from "./Navbar_View";
import Footer from "./Contact_Footer_View";
import { useEffect } from "react";


export function Homescreen() 
{
    // useEffect(() => {
    //     // Make the API call when the component mounts
    //     fetch('https://smaeckridderkerk.nl/smaeck-web/api/api.php')
    //       .then(response => response.text())
    //       .then(data => console.log(data))
    //       .catch(error => console.error('Error:', error));
    //   }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

    return (
        <>
            <Navbar />

            {/* <div>
                <img className="max-w-full" src={require("./images/20230401_102333.jpg")} alt=""/>
            </div> */}

            
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-[1%] md:p-[1%] gap-[1%] p-[2%] mb-4 md:mb-0">
                <img className="max-w-[100%]" src={require("./images/IMG-20240224-WA0003smaller.jpg")} alt=""/>
                <div className="bg-white border border-gray-200 p-[10%]">
                    <h1 className="text-3xl font-bold ">WELKOM BIJ SMAECK</h1>
                    <p className=""><br></br>
                    Bij ons draait alles om passie en liefde voor het bakken. 
                    In onze gezellige winkel vind je de heerlijkste zelfgemaakte gebakjes, koeken en taarten, allemaal met zorg en aandacht bereid. 
                    Of je nu trek hebt in een heerlijk stuk taart, de lekkerste brownie of gewoon zin hebt in een knapperige koek bij de koffie - wij hebben het allemaal.<br></br><br></br>

                    Maar dat is nog niet alles. We nodigen je uit om onze rijkgevulde bonbons, romige chocolade en smeuïge nougat te ontdekken. Het perfecte cadeau voor jezelf of iemand die je dierbaar is.<br></br><br></br>

                    Wat onze winkel zo bijzonder maakt, is de persoonlijke touch en de huiselijke sfeer en dagelijks vers gebakken producten. 
                    We zijn een kleine bakkerij en om verspilling tegen te gaan zijn de hoeveelheden beperkt.  Dus wilt u zeker zijn van iets lekkers aarzel niet om te bestellen. <br></br><br></br>

                    Kom langs bij Smaeck en laat je verleiden door onze zoete lekkernijen. We kunnen niet wachten om je te verwelkomen en je kennis te laten maken met de smaken die ons zo bijzonder maken.<br></br><br></br>

                    Tot snel!
                    </p>
                </div>
            </div>
            
            {/* web */}
            <div className="hidden md:grid grid-cols-5 gap-[1%] p-[1%]">
                <div>
                    <img className="mx-auto max-w-full" src={require("./images/IMG-20240220-WA0047.jpg")} alt=""/>
                </div>
                <div>
                    <img className="mx-auto max-w-full" src={require("./images/IMG-20240220-WA0008.jpg")} alt=""/>
                </div>
                <div>
                    <img className="mx-auto max-w-full" src={require("./images/IMG-20240220-WA0044.jpg")} alt=""/>
                </div>
                <div>
                    <img className="mx-auto max-w-full" src={require("./images/IMG-20240220-WA0032.jpg")} alt=""/>
                </div>
                <div>
                    <img className="mx-auto max-w-full" src={require("./images/IMG-20240220-WA0048.jpg")} alt=""/>
                </div>
            </div>

            {/* mobile */}
            <div className="grid md:hidden grid-cols-2 grid-rows-2 gap-[2%] p-[2%] mb-3">
                <div>
                    <img className="mx-auto max-w-full" src={require("./images/IMG-20240220-WA0008.jpg")} alt=""/>
                </div>
                <div>
                    <img className="mx-auto max-w-full" src={require("./images/IMG-20240220-WA0044.jpg")} alt=""/>
                </div>
                <div>
                    <img className="mx-auto max-w-full" src={require("./images/IMG-20240220-WA0032.jpg")} alt=""/>
                </div>
                <div>
                    <img className="mx-auto max-w-full" src={require("./images/IMG-20240220-WA0048.jpg")} alt=""/>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default Homescreen;