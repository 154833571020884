import { redirect } from "react-router-dom";
type Order = {
    
}

class OrderController {
    static PlaceOrder = async (firstname: string, lastname: string, email: string, phone: string, deliverydate: string, description: string) => {
        try {
            const response = await fetch('https://smaeckridderkerk.nl/api/api.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    'function': 'placeOrder',
                    'firstname': firstname,
                    'lastname': lastname,
                    'email': email,
                    'phone': phone,
                    'deliverydate': deliverydate,
                    'description': description
                }).toString(),
            });
                const data = await response.json();
                console.log(data);

                if (data.success) {
                    console.log('Order placed successful');
                    // Redirect to the dashboard
                    window.location.href = "#/order-confirmation";
                } else {
                    console.log('Order placed failed');
                }
        } catch (error) {
            console.error('Error:', error);
        }
    }

        


}

export default OrderController

