// link import for the routing
import { Link } from "react-router-dom";

// import navbar
import Navbar from "./Navbar_View";

export function Homescreen() 
{
    return (
        <>
            {/* calls navbar from 'Navbar_View' */}
            <Navbar />

            {/* page intro for big screen */}
            <div className="hidden md:flex justify-center h-96 mt-20 max-w-screen">
                <img className="" src={require("./images/IMG-20240220-WA0003.jpg")} alt=""/>

                <div className="flex flex-col justify-center items-center h-96 bg-[#668F8F] px-20">
                    <p className="text-white">Voor meer informatie of speciale verzoeken</p>
                    <h1 className="text-3xl font-bold text-white">ZOEK CONTACT</h1>
                </div>

                <img className="" src={require("./images/IMG-20240224-WA0001.jpg")} alt=""/>
            </div>

            {/* page intro for phone screens */}
            <div className="flex md:hidden h-80 mt-20 max-w-screen">
                <img className="mx-auto max-w-full" src={require("./images/half_pic.png")} alt=""/>

                <div className="flex flex-col justify-center items-center h-80 bg-[#668F8F] px-10">
                    <p className="text-white">Voor meer informatie of speciale verzoeken</p>
                    <h1 className="text-3xl font-bold text-white">ZOEK CONTACT</h1>
                </div>
            </div>

            {/* div that causes all info below to display in rows, and in colums if the screen gets too small*/}
            <div className="flex justify-between flex-col md:flex-row md:p-20">

                {/* opening hours */}
                <div className="flex flex-col items-center p-8">
                    <h1 className="text-3xl pb-3">OPENINGSTIJDEN</h1>
                    
                    {/* days and hours of opening */}
                    <p className="font-bold">Woensdag</p>
                    <p>9:00 - 16:00</p>

                    <p className="font-bold">Donderdag</p>
                    <p>9:00 - 16:00</p>

                    <p className="font-bold">Vrijdag</p>
                    <p>9:00 - 16:00</p>

                    <p className="font-bold">Zaterdag</p>
                    <p>9:00 - 16:00</p>
                </div>

                {/* location and directions */}
                <div className="flex flex-col items-center p-8">
                    <h1 className="text-3xl pb-3">LOCATIE</h1>
                    <p>Vlietplein 8 Ridderkerk</p>

                    {/* button with link to maps with directions */}
                    <div className="p-4">
                        <button className="px-3 py-2 border-2 bg-transparent border-gray-300 hover:bg-white hover:border-[#8D5B4C]">
                            <Link to="https://www.google.nl/maps/place/Smaeck/@51.8655077,4.6172581,17z/data=!3m1!4b1!4m6!3m5!1s0x47c42dbb689e731f:0x753bb40a2a19a6aa!8m2!3d51.8655077!4d4.6198384!16s%2Fg%2F11v01y1_z1?entry=ttu" target="_blank" rel="noopener noreferrer">
                                Routebeschrijving
                            </Link>
                        </button>
                    </div>
                </div>

                {/* contact */}
                <div className="flex flex-col items-center p-8">
                    <h1 className="text-3xl pb-3">CONTACT</h1>
                    
                    {/* email and phonenumber */}
                    <p className="font-bold">Email</p>
                    <p>smaeckridderkerk@hotmail.com</p>

                    <p className="font-bold">Telefoonnummer</p>
                    <p>06 23835962</p>
                </div>

                {/* socials */}
                <div className="flex flex-col items-center p-8">
                    <h1 className="text-3xl pb-3">SOCIAL MEDIA</h1>

                    {/* button with link to instagram */}
                    <div className="p-4">
                        <button className="px-3 py-2 border-2 bg-transparent border-gray-300 hover:bg-white hover:border-[#8D5B4C]">
                            <Link to="https://www.instagram.com/smaeckridderkerk/" target="_blank" rel="noopener noreferrer">
                                Instagram
                            </Link>
                        </button>
                    </div>

                    {/* button with link to facebook */}
                    <div className="p-4">
                        <button className="px-3 py-2 border-2 bg-transparent border-gray-300 hover:bg-white hover:border-[#8D5B4C]">
                            <Link to="https://www.facebook.com/people/Smaeck/100064472238646/" target="_blank" rel="noopener noreferrer">
                                Facebook
                            </Link>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Homescreen;