// link import for the routing
import { Link } from "react-router-dom";

export function Homescreen() 
{
    return (
        <>
            {/* div that causes all info below to display in rows, and in colums if the screen gets too small*/}
            <div className="bg-white flex justify-between flex-col md:flex-row md:p-20 border-t border-gray-200">

                {/* opening hours */}
                <div className="flex flex-col items-center p-4">
                    <h1 className="text-3xl pb-3">OPENINGSTIJDEN</h1>
                    
                    {/* days and hours of opening */}
                    <p className="font-bold">Woensdag</p>
                    <p>9:00 - 16:00</p>

                    <p className="font-bold">Donderdag</p>
                    <p>9:00 - 16:00</p>

                    <p className="font-bold">Vrijdag</p>
                    <p>9:00 - 16:00</p>

                    <p className="font-bold">Zaterdag</p>
                    <p>9:00 - 16:00</p>
                </div>

                {/* location and directions */}
                <div className="flex flex-col items-center p-4">
                    <h1 className="text-3xl pb-3">LOCATIE</h1>
                    <p>Vlietplein 8 Ridderkerk</p>

                    {/* button with link to maps with directions */}
                    <div className="p-4">
                        <button className="px-3 py-2 border-2 bg-transparent border-gray-300 hover:bg-[#F3F1EA] hover:border-[#8D5B4C]">
                            <Link to="https://www.google.nl/maps/place/Smaeck/@51.8655077,4.6172581,17z/data=!3m1!4b1!4m6!3m5!1s0x47c42dbb689e731f:0x753bb40a2a19a6aa!8m2!3d51.8655077!4d4.6198384!16s%2Fg%2F11v01y1_z1?entry=ttu" target="_blank" rel="noopener noreferrer">
                                Routebeschrijving
                            </Link>
                        </button>
                    </div>
                </div>

                {/* contact */}
                <div className="flex flex-col items-center p-4">
                    <h1 className="text-3xl pb-3">CONTACT</h1>
                    
                    {/* email and phonenumber */}
                    <p className="font-bold">Email</p>
                    <p>smaeckridderkerk@hotmail.com</p>

                    <p className="font-bold">Telefoonnummer</p>
                    <p>06 23835962</p>
                </div>

                {/* socials */}
                <div className="flex flex-col items-center p-4">
                    <h1 className="text-3xl pb-3">SOCIAL MEDIA</h1>

                    {/* button with link to instagram */}
                    <div className="p-4">
                        <button className="px-3 py-2 border-2 bg-transparent border-gray-300 hover:bg-[#F3F1EA] hover:border-[#8D5B4C]">
                            <Link to="https://www.instagram.com/smaeckridderkerk/" target="_blank" rel="noopener noreferrer">
                                Instagram
                            </Link>
                        </button>
                    </div>

                    {/* button with link to facebook */}
                    <div className="p-4">
                        <button className="px-3 py-2 border-2 bg-transparent border-gray-300 hover:bg-[#F3F1EA] hover:border-[#8D5B4C]">
                            <Link to="https://www.facebook.com/people/Smaeck/100064472238646/" target="_blank" rel="noopener noreferrer">
                                Facebook
                            </Link>
                        </button>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Homescreen;