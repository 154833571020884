import React, { useState, useContext, useEffect } from 'react';

import Navbar from './Navbar_View';
import Footer from './Contact_Footer_View';
import BlogController from "../Controllers/Blog_Controller";

import { useLocation, useNavigate } from 'react-router-dom';
import LoggedInCheckController from '../Controllers/Loggedin_check_Controller';


const AdminHomeScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState({ name: '', id: '' });
  const [blogs, setBlogs] = useState<{ Title: string; BlogText: string; Image: string }[]>([]);
  const [blogtitle, setBlogTitle] = useState('');
  const [blogtext, setBlogText] = useState('');
  const [image, setImage] = useState(null);

  useEffect(() => {
      // If user is not defined, redirect to login
      const checkLoginStatus = async () => {
          if (location.state === undefined || location.state === null) {
              navigate('/login');
              return;
          } else {
              const isLoggedIn = await LoggedInCheckController.LoggedInCheck(location.state.name, location.state.id);
              if (isLoggedIn) {
                  setUser({ name: location.state.name, id: location.state.id });
              } else {
                  navigate('/login');
              }
          }
      };

      checkLoginStatus();

      const GetAllBlogPosts = async () => {
          const data = await BlogController.GetAllBlogPosts('getAllBlogPosts');
          setBlogs(data.blogs); // Update state with fetched blog posts
      };
      GetAllBlogPosts();
  }, [location.state, navigate]);

  const handleBlogTitleChange = (e: any) => {
      setBlogTitle(e.target.value);
  };

  const handleBlogTextChange = (e: any) => {
      setBlogText(e.target.value);
  };

  const handleImageChange = (e: any) => {
      setImage(e.target.files[0]);
  };

  const handleBlogSubmit = async () => {
      const formData = new FormData();
      formData.append('blogTitle', blogtitle);
      formData.append('blogText', blogtext);
      if (image) {
          formData.append('image', image);
      }

      if (await BlogController.CreateBlogPost(formData, 'createBlogPost')) {
        alert('Blog Added');
      } else {
        alert('Blog Failed to add');
      }
  };
  
    return (
        <>
            <Navbar />
            <div className="container mx-auto">
                <div className="mt-20 mb-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    <div className="bg-slate-800 p-5">
                        <label>
                            Blog Title:
                            <input type="text" name="blogtitle" value={blogtitle} onChange={handleBlogTitleChange} />
                        </label>
                        <label>
                            Blog Text:
                            <input type="text" name="blogtext" value={blogtext} onChange={handleBlogTextChange} />
                        </label>
                        <label>
                            Blog Image:
                            <input type="file" name="blogimage" onChange={handleImageChange} />
                        </label>
                        <button type="button" onClick={handleBlogSubmit} className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2">
                            Submit
                        </button>
                    </div>
                    <div className="bg-slate-500 p-5">
                      {blogs.length > 0 ? (
                          blogs.map((blog, index) => (
                              <div key={index} className="mb-4">
                                  <h2 className="text-xl font-bold">{blog.Title}</h2>
                                  <p>{blog.BlogText}</p>
                                  {blog.Image && <img src={`https://smaeckridderkerk.nl/${blog.Image}`} alt={blog.Title} className="w-full h-auto" />}
                              </div>
                          ))
                      ) : (
                          <p>No blogs found</p>
                      )}
                    </div>
                    <div className="bg-slate-600 p-5">div3</div>
                    <div className="bg-slate-200 p-5">div4</div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AdminHomeScreen;