import { redirect } from "react-router-dom";

class LoggedInCheckController {
    static async LoggedInCheck (name : string, id: string) {
        try {
            const response = await fetch('https://smaeckridderkerk.nl/api/api.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    'function': 'loggedinCheck',
                    'username': name,
                    'id': id,
                }).toString(),
            });

            const data = await response.json();
            if (data.success) {
                console.log('Check completed');
                return true;
            }
            else {
                console.log('Check failed');
                return false;
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
}

export default LoggedInCheckController;

