import { redirect } from "react-router-dom";

class LoginController {
    static async loginCheck(email: string, password: string) {
        try {
            const response = await fetch('https://smaeckridderkerk.nl/api/api.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    'function': 'loginCheck',
                    'username': email,
                    'password': password,
                }).toString(),
            });

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
}

export default LoginController;

